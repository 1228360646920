import type { PropsWithChildren } from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';

export const LoginPanel = ({ children }: PropsWithChildren) => (
  <Box
    as="section"
    bgGradient={{ md: 'linear(to-r, green.600, green.600)' }}
    minHeight="100vh"
    py="14"
  >
    <Box
      bg={useColorModeValue('white', 'gray.800')}
      maxW="lg"
      mx="auto"
      p="4"
      rounded={{ md: '2xl' }}
      textAlign="center"
    >
      <Box maxW="md" mx="auto" px={6}>
        {children}
      </Box>
    </Box>
  </Box>
);
