import { useState } from 'react';
import {
  Button,
  Stack,
  useColorModeValue,
  Text,
  Link,
  Image,
  Center,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Divider,
  ButtonGroup,
  Checkbox,
  useToast,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { LoginPanel } from '@/components/Login/LoginPanel';
import PasswordField from '@/components/Login/PasswordField';
import {
  signInWithEmailAndPassword,
  signInWithGoogle,
} from '@/stores/AuthStore';

export default function Login() {
  const router = useRouter();
  const toast = useToast();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [passwordError, setPasswordError] = useState('');

  const validateFields = ({ password }: { password: string }) => {
    let isValid = true;

    if (password.trim() === '') {
      setPasswordError('Password field cannot be empty.');
      isValid = false;
    }

    return isValid;
  };

  const toastSignInError = (error: string) => {
    toast({
      title: 'Failed to sign in',
      description: error,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  const handleSignInWithGoogle = async () => {
    const result = await signInWithGoogle();

    if (result.error) {
      toastSignInError(result.error);
      return;
    }
  };

  const handleSignIn = async () => {
    const isValid = validateFields({ password });
    if (!isValid) {
      return;
    }

    const result = await signInWithEmailAndPassword({ email, password });

    if (result.error) {
      setPasswordError(result.error);
      toastSignInError(result.error);
      return;
    }
  };

  return (
    <LoginPanel>
      <Stack spacing="7" textAlign="center">
        <Stack spacing="0">
          <Center py={5}>
            <Image alt="Talin" src="/logoSmall.png" width="120px" />
          </Center>

          <Text
            color={useColorModeValue('gray.600', 'gray.400')}
            fontWeight="medium"
          >
            We help recruiters and in-house talent teams automate candidate
            outreach.
          </Text>
        </Stack>

        <Stack pt={2} spacing="6">
          <Stack spacing="5">
            <FormControl>
              <FormLabel
                color="#2d3748"
                fontSize="sm"
                fontWeight="600"
                htmlFor="email"
              >
                Email
              </FormLabel>

              <Input
                id="email"
                type="email"
                value={email}
                onChange={(e) => {
                  setPasswordError('');
                  setEmail(e.target.value);
                }}
              />
            </FormControl>

            <PasswordField
              hasError={passwordError.length > 0}
              passwordErrorText={passwordError}
              value={password}
              onChange={(e) => {
                setPasswordError('');
                setPassword(e.target.value);
              }}
              onKeyPress={async (e) => {
                if (e.key === 'Enter') {
                  await handleSignIn();
                }
              }}
            />

            <HStack justify="space-between">
              <Checkbox colorScheme="green" defaultChecked fontWeight="600">
                <Text fontSize="sm">Remember me</Text>
              </Checkbox>

              <Button
                colorScheme="green"
                size="sm"
                variant="link"
                onClick={() => router.push('/forgotPassword')}
              >
                Forgot password?{' '}
              </Button>
            </HStack>
          </Stack>

          <Stack spacing="6">
            <Button colorScheme="green" onClick={handleSignIn}>
              Sign in
            </Button>
          </Stack>
        </Stack>

        <HStack>
          <Divider />

          <Text color="muted" fontSize="sm" whiteSpace="nowrap">
            or
          </Text>

          <Divider />
        </HStack>

        <Center>
          <ButtonGroup variant="outline" width="200px">
            <Button
              _hover={{}}
              bgImage="GoogleOAuth.png"
              bgSize="cover"
              size="lg"
              variant="ghost"
              width="full"
              onClick={handleSignInWithGoogle}
            />
          </ButtonGroup>
        </Center>
      </Stack>

      <HStack justify="center" pt={6} spacing="1">
        <Text color="muted">{`Don't have an account?`}</Text>

        <Button
          colorScheme="green"
          variant="link"
          onClick={() => router.push('/signup')}
        >
          Sign up
        </Button>
      </HStack>

      <Text
        color={useColorModeValue('gray.600', 'gray.400')}
        fontSize="xs"
        maxW="sm"
        mt={6}
        mx="auto"
      >
        By continuing, you acknowledge that you have read, understood, and agree
        to our <Link href="/terms">terms and conditions</Link>.
      </Text>
    </LoginPanel>
  );
}
