import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import {
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  FormErrorMessage,
} from '@chakra-ui/react';
import { HiEye, HiEyeOff } from 'react-icons/hi';

interface PasswordFieldProps {
  hasError: boolean;
  passwordErrorText?: string;
  value: string;
  onChange: (e: any) => void;
  onKeyPress?: (e: any) => void;
}

const PasswordField = forwardRef(
  (props: PasswordFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { isOpen, onToggle } = useDisclosure();

    const onClickReveal = () => {
      onToggle();
    };

    const { hasError, passwordErrorText, ...inputProps } = props;

    return (
      <FormControl isInvalid={hasError}>
        <FormLabel
          color="#2d3748"
          fontSize="sm"
          fontWeight="600"
          htmlFor="password"
        >
          Password
        </FormLabel>

        <InputGroup>
          <InputRightElement>
            <IconButton
              aria-label={isOpen ? 'Mask password' : 'Reveal password'}
              icon={isOpen ? <HiEyeOff /> : <HiEye />}
              variant="link"
              onClick={onClickReveal}
            />
          </InputRightElement>

          <Input
            ref={ref}
            id="password"
            name="password"
            required
            type={isOpen ? 'text' : 'password'}
            {...inputProps}
          />
        </InputGroup>

        {props.hasError && (
          <FormErrorMessage textAlign="left">
            {passwordErrorText ??
              `Looks like your password is incorrect, please double check and try
          again!`}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  }
);

PasswordField.displayName = 'PasswordField';

export default PasswordField;
